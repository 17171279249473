const variant = JSON.parse(localStorage.getItem("themeVariant"))
    ? JSON.parse(localStorage.getItem("themeVariant"))
    : "light";

if (variant == "dark") {
    document.body.classList.add("dark");
} else {
    document.body.classList.remove("dark");
}
const theme = {
    defaultTheme: variant,
    themes: {
        light: {
            dark: false,
            colors: {
                // primary: "#5C02F8",
                // primary: "#9155FD",
                // primary: "#2091ec",
                primary: "#0a89f1",
                secondary: "#1867C0",
                "on-secondary": "#fff",
                success: "#56CA00",
                info: "#16B1FF",
                warning: "#db9a00",
                error: "#FF4C51",
                "on-primary": "#FFFFFF",
                "on-success": "#FFFFFF",
                "on-warning": "#FFFFFF",
                surface: "#FFFBFE",
                "surface-light": "#FFFBFE", //backgroun toolbar
                background: "#e3e0ff",
                // background: "#dbd8fa",
                "on-background": "#3A3541", // warna font di background
                "on-surface": "#0d0d0f", //warna font di card
                // 'on-surface': '#3A3541',
                "on-surface-variant": "#FFFFFF", //font di toolbar background
                // 'grey-50': '#FAFAFA',
                // 'grey-100': '#F0F2F8',
                // 'grey-200': '#EEEEEE',
                // 'grey-300': '#E0E0E0',
                // 'grey-400': '#BDBDBD',
                // 'grey-500': '#9E9E9E',
                // 'grey-600': '#757575',
                // 'grey-700': '#616161',
                // 'grey-800': '#424242',
                // 'grey-900': '#212121',
                "perfect-scrollbar-thumb": "#DBDADE",
                // 'skin-bordered-background': '#fff',
                // 'skin-bordered-surface': '#fff',
            },
            variables: {
                "code-color": "#d400ff",
                "overlay-scrim-background": "#3A3541",
                "overlay-scrim-opacity": 0.5,
                "hover-opacity": 0.04,
                "focus-opacity": 0.1,
                "selected-opacity": 0.12,
                "activated-opacity": 0.1,
                "pressed-opacity": 0.14,
                "dragged-opacity": 0.1,
                // 'border-color': '#3A3541',
                // 'border-color': '#0d0d0f',
                // 'border-opacity': 1,
                "divider-border-color": "#0d0d0f",
                // 'table-header-background': '#F9FAFC',
                // 'custom-background': '#F9F8F9',

                // Shadows
                // 'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.08)',
                // 'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.12)',
                // 'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.04)',
            },
        },
        dark: {
            dark: true,
            colors: {
                // background: "#28243D",
                // surface: "#332F4E",
                background: "#0b1527",
                surface: "#101c35",
                "surface-bright": "#FFFFFF",
                // "surface-light": "#332F4E", //backgroun toolbar
                "surface-light": "#101c35", //backgroun toolbar
                "surface-variant": "#424242",
                "on-surface-variant": "#EEEEEE",
                // primary: "#9155FD",
                // "primary-darken-1": "#9155FD",
                // primary: "#9155FD",
                primary: "#0a89f1",
                "primary-darken-1": "#7b68ee",
                secondary: "#1867C0",
                "secondary-darken-1": "#1867C0",
                error: "#FF4C51",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FB8C00",
            },
            variables: {
                "code-color": "#d400ff",
                "overlay-scrim-background": "#2C2942",
                "overlay-scrim-opacity": 0.6,
                "hover-opacity": 0.04,
                "focus-opacity": 0.1,
                "selected-opacity": 0.12,
                "activated-opacity": 0.1,
                "pressed-opacity": 0.14,
                "dragged-opacity": 0.1,
                "border-color": "#eae7f8",
                // 'border-opacity': 0.30,
                "table-header-background": "#3D3759",
                "custom-background": "#373452",

                // Shadows
                "shadow-key-umbra-opacity": "rgba(20, 18, 33, 0.08)",
                "shadow-key-penumbra-opacity": "rgba(20, 18, 33, 0.12)",
                "shadow-key-ambient-opacity": "rgba(20, 18, 33, 0.04)",
            },
        },
        // dark: {
        //   dark: true,
        //   colors: {
        //     'primary': '#9155FD',
        //     'secondary': '#8A8D93',
        //     'on-secondary': '#fff',
        //     'success': '#56CA00',
        //     'info': '#16B1FF',
        //     'warning': '#FFB400',
        //     'error': '#FF4C51',
        //     'on-primary': '#FFFFFF',
        //     'on-success': '#FFFFFF',
        //     'on-warning': '#FFFFFF',
        //     'background': '#28243D',
        //     'on-background': '#28243D',
        //     'surface': '#332F4E', //card color
        //     'on-surface': '#E7E3FC', //text color in card
        //     'surface-variant': '#332F4E', //toolbar background
        //     'on-surface-variant': '#E7E3FC', //toolbar background
        //     // 'on-surface': '#E7E3FC',
        //     'grey-50': '#2A2E42',
        //     'grey-100': '#474360',
        //     'grey-200': '#4A5072',
        //     'grey-300': '#5E6692',
        //     'grey-400': '#7983BB',
        //     'grey-500': '#8692D0',
        //     'grey-600': '#AAB3DE',
        //     'grey-700': '#B6BEE3',
        //     'grey-800': '#CFD3EC',
        //     'grey-900': '#E7E9F6',
        //     'perfect-scrollbar-thumb': '#4A5072',
        //     'skin-bordered-background': '#332f4e',
        //     'skin-bordered-surface': '#ffffff',
        //   },
        //   variables: {
        //     'code-color': '#d400ff',
        //     'overlay-scrim-background': '#2C2942',
        //     'overlay-scrim-opacity': 0.6,
        //     'hover-opacity': 0.04,
        //     'focus-opacity': 0.1,
        //     'selected-opacity': 0.12,
        //     'activated-opacity': 0.1,
        //     'pressed-opacity': 0.14,
        //     'dragged-opacity': 0.1,
        //     'border-color': '#E7E3FC',
        //     'table-header-background': '#3D3759',
        //     'custom-background': '#373452',

        //     // Shadows
        //     'shadow-key-umbra-opacity': 'rgba(20, 18, 33, 0.08)',
        //     'shadow-key-penumbra-opacity': 'rgba(20, 18, 33, 0.12)',
        //     'shadow-key-ambient-opacity': 'rgba(20, 18, 33, 0.04)',
        //   },
        // },
    },
};

export default theme;
