export default [
    {
        path: "/attendances",
        name: "attendance",
        component: () => import("@/pages/attendance/Attendance.vue"),
        meta: {
            pageTitle: "Absensi",
            icon: "icon-[ph--clock-countdown-duotone]",
            layout: "LayoutFull",
            resource: "peserta",
            action: "read",
        },
    },
    {
        path: "/attendance-logs/:ulid?",
        name: "attendanceLogs",
        component: () => import("@/pages/attendance/AttendanceLogs.vue"),
        meta: {
            pageTitle: "Data Absensi",
            icon: "icon-[ph--clock-countdown-duotone]",
            layout: "LayoutFull",
            resource: ["peserta", "admin"],
            action: "read",
        },
    },
];
