const menu = [
    {
        name: "home",
        icon: "icon-[ic--twotone-home]",
        title: "Beranda",
        access: "auth",
        type: "btn",
    },
    {
        name: "attendance",
        icon: "icon-[ph--clock-countdown-duotone]",
        title: "Absensi",
        access: "peserta",
        type: "btn",
    },
    {
        name: "activities",
        icon: "icon-[ic--twotone-library-books]",
        title: "Aktivitas",
        access: "peserta",
        type: "btn",
    },

    {
        name: "studentStase",
        icon: "icon-[ic--outline-school]",
        title: "Stase Peserta",
        access: "admin",
        type: "btn",
    },
    {
        icon: "icon-[tabler--report-analytics]",
        title: "Laporan",
        access: "report",
        type: "dropdown",
        list: [
            {
                name: "penilaian",
                icon: "icon-[tabler--clipboard-check]",
                title: "Penilaian",
                access: "report",
                type: "btn",
            },
            {
                name: "attendanceReport",
                icon: "icon-[tabler--report]",
                title: "Laporan Absensi",
                access: "report",
            },
            {
                name: "activityReport",
                icon: "icon-[ic--twotone-library-books]",
                title: "Aktivitas",
                access: "report",
                type: "btn",
            },
        ],
    },
    // { name : 'absensi', icon : 'mdi-clock', title: 'Absensi', access: 'peserta', type: 'btn' },
    // { name : 'assessment', icon : 'mdi-check-decagram-outline', title: 'Penilaian', access: 'admin', type: 'btn' },
    // { name : 'stdgradelist', icon : 'mdi-check-decagram-outline', title: 'Nilai Mahasiswa', access: 'dosen', type: 'btn' },
    {
        icon: "icon-[tabler--database]",
        title: "Master Data",
        access: "admin",
        type: "dropdown",
        list: [
            {
                name: "students",
                icon: "icon-[mdi--account-student]",
                title: "Peserta Didik",
                access: "admin",
            },
            {
                name: "lecturers",
                icon: "icon-[mdi--lecture]",
                title: "Dosen",
                access: "admin",
            },
            {
                name: "doctor",
                icon: "icon-[tabler--stethoscope]",
                title: "Dokter",
                access: "admin",
            },
            {
                name: "univeristies",
                icon: "icon-[tabler--building-bank]",
                title: "Universitas",
                access: "admin",
            },
            {
                name: "standarPenilaian",
                icon: "icon-[tabler--checkbox]",
                title: "Standar Penilaian",
                access: "admin",
            },
            {
                name: "jenisPenilaian",
                icon: "icon-[tabler--list-check]",
                title: "Jenis Penilaian",
                access: "admin",
            },
            {
                name: "stases",
                icon: "icon-[tabler--building-hospital]",
                title: "Stase",
                access: "admin",
            },
            {
                name: "admins",
                icon: "icon-[ic--twotone-manage-accounts]",
                title: "Admin",
                access: "sysdev",
            },
        ],
    },
];

export default menu;
