// const helper = {
//     install(app) {
//         app.config.globalProperties.$asset = (path) => {
//             var base_path = window._asset || '';
//             let link = path ? path : '';
//             return base_path + link ;
//         }
//     }
// }

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import toObject from "dayjs/plugin/toObject";
import "dayjs/locale/id";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(toObject);
dayjs.extend(toObject);
dayjs.locale("id");

export const dateTime = dayjs;

import { UAParser } from "ua-parser-js";

let parser = new UAParser(navigator.userAgent);
let browser = parser.getBrowser();
let oldIos =
    browser.name.includes("Safari") &&
    !browser.name.includes("Chrome") &&
    browser.version.split(".")[0] < 16;

// export default helper;
import md5 from "md5";

import { useToast } from "vue-toastification";
import Swal from "sweetalert2";

export function asset(path) {
    var base_path = window._asset || "";
    let link = path ? path : "";
    return base_path + link;
}

export function assetStorage(path) {
    let link = path ? path : "";
    return "https://ftprspendidikan.itrsudcibinong.com/" + link;
}

export function replaceByDefault(e) {
    e.target.src = asset("images/3d-characters/pose-m-1.png");
}

export function replaceAvaByDefault(e) {
    e.target.src = asset("images/avatars/1.png");
}

export function toastSuccess(text) {
    if (oldIos) {
        Swal.fire({
            title: "Berhasil!",
            text: text ? text : "Berhasil",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
        });
    } else {
        useToast().success(text ? text : "Berhasil", {
            timeout: 2000,
        });
    }
}

export function toastError(text) {
    if (oldIos) {
        Swal.fire({
            title: "Gagal!",
            text: text ? text : "Error, something went wrong",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
        });
    } else {
        useToast().error(text ? text : "Error, something went wrong", {
            timeout: 2000,
        });
    }
}

export function countDecimals(value) {
    if (Math.floor(value) !== value && value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}

export function makeHash(value) {
    return md5(value);
}
