export default [
    {
        path: "/report/penilaian",
        name: "penilaian",
        component: () => import("@/pages/penilaian/Penilaian.vue"),
        meta: {
            pageTitle: "Penilaian",
            icon: "icon-[tabler--clipboard-check]",
            layout: "LayoutFull",
            resource: "report",
            action: "read",
        },
    },
    {
        path: "/report/activity",
        name: "activityReport",
        component: () => import("@/pages/activityReport/ActivityReport.vue"),
        meta: {
            pageTitle: "Aktivitas Peserta",
            icon: "icon-[ic--twotone-library-books]",
            layout: "LayoutFull",
            resource: "report",
            action: "read",
        },
    },
    {
        path: "/report/attendance",
        name: "attendanceReport",
        component: () => import("@/pages/attendance/AttendanceReport.vue"),
        meta: {
            pageTitle: "Laporan Absensi",
            icon: "icon-[tabler--report]",
            layout: "LayoutFull",
            resource: "report",
            action: "read",
        },
    },
];
