import { createWebHistory, createRouter } from "vue-router";
import redirect from "./redirect";
import masterData from "./masterData";
import attendance from "./attendance";
import account from "./account";
import guest from "./guest";
import report from "./report";
import ability from "@/plugins/casl/ability";
import { nextTick } from "vue";

const routes = [
    ...redirect,
    ...masterData,
    ...attendance,
    ...account,
    ...guest,
    ...report,
    {
        path: "/home",
        name: "home",
        component: () => import("@/pages/home/HomeNew.vue"),
        meta: {
            pageTitle: "Beranda",
            icon: "icon-[ic--twotone-home]",
            layout: "LayoutFull",
            resource: "auth",
            action: "read",
        },
    },
    {
        path: "/activities",
        name: "activities",
        component: () => import("@/pages/activities/Activities.vue"),
        meta: {
            pageTitle: "Aktivitas",
            icon: "icon-[ic--twotone-library-books]",
            layout: "LayoutFull",
            resource: "peserta",
            action: "read",
        },
    },

    // {
    //     path: "/assessment",
    //     name: "assessment",
    //     component: () => import("@/pages/assessment/Assessment.vue"),
    //     meta: {
    //         pageTitle: "Penilaian",
    //         icon: "mdi-check-decagram-outline",
    //         layout: "LayoutFull",
    //         resource: "admin",
    //         action: "read",
    //     },
    // },
    // {
    //     path: "/about",
    //     name: "About",
    //     component: () => import("@/pages/about.vue"),
    //     meta: {
    //         layout: "LayoutFull",
    //         resource: "all",
    //         action: "read",
    //     },
    // },
    {
        path: "/student-stase",
        name: "studentStase",
        component: () => import("@/pages/studentStase/StudentStase.vue"),
        meta: {
            pageTitle: "Stase Peserta",
            icon: "icon-[ic--outline-school]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
];

const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (from.meta.savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const canNavigate = to.matched.some((route) => {
        if (Array.isArray(route.meta.resource)) {
            let resource = route.meta.resource;
            const result = resource.some((r) => {
                return ability.can(route.meta.action || "read", r);
            });
            return result;
        } else {
            return ability.can(
                route.meta.action || "read",
                route.meta.resource
            );
        }
    });
    if (!canNavigate) {
        return next("/not-authorized");
    }
    next();
});

router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609

    nextTick().then(() => {
        document.title = to.meta.pageTitle + " | SI-CERDIK" || "SI-CERDIK";
    });
});

export default router;
