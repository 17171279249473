import "./bootstrap";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import filters from "./filters";
import vuetify from "./plugins/vuetify";

//axios global
axios.defaults.baseURL = window._asset;

// vue toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    position: "top-right",
};
// casl
import { abilitiesPlugin } from "@casl/vue";
import ability from "./plugins/casl/ability";

//pinia
import { createPinia } from "pinia";
const pinia = createPinia();

//sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const optionsSwal = {
    // confirmButtonColor: '#00A2EA',
    // cancelButtonColor: '#5f5f5f',
    confirmButtonText: "Konfirmasi",
    cancelButtonText: "Batal",
};

//google analytics
import { analytics } from "./plugins/firebase";

import VueDragscroll from "vue-dragscroll";
import Camera from "simple-vue-camera";

//picture viewer
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
VueViewer.setDefaults({
    navbar: false,
    title: false,
    toolbar: false,
});

const app = createApp(App);

app.use(VueDragscroll);
app.use(VueViewer);
app.use(VueSweetalert2, optionsSwal);
app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
});
app.use(analytics);
app.use(Toast, options);
app.use(router);
app.use(vuetify);
app.use(pinia);
app.component("camera", Camera);

app.config.globalProperties.$filters = filters;
// app.config.globalProperties.$asset = (path) => {
//     var base_path = window._asset || '';
//     let link = path ? path : '';
//     return base_path + link ;
// }
app.mount("#app");
