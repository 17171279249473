import { DateTime } from "luxon";
import { dateTime } from "./mixins";

const filters = {
    formatMoney(value) {
        return new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
        }).format(value);
    },
    toDate(value) {
        return DateTime.fromISO(value)
            .setLocale("id")
            .toLocaleString(DateTime.DATE_FULL);
    },
    toTime(value) {
        return DateTime.fromSQL(value)
            .setLocale("id")
            .toLocaleString(DateTime.TIME_24_SIMPLE);
    },
    toTimeOnly(value) {
        return dateTime(value).format("HH:mm:ss");
    },
    gender(value) {
        if (value == "L") {
            return "Laki-laki";
        } else {
            return "Perempuan";
        }
    },
};

// Vue.filter('fulldate', function(value){
//     return moment(value).format('dddd, DD MMMM YYYY')
// });

export default filters;
