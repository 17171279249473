<template>
    <v-app-bar
        color="#7344ed"
        elevation="10"
        location="bottom"
        class="appbar-background"
    >
        <!-- :image="asset('images/header/liquid-cheese.webp')" -->
        <div class="boxed-navbar !ml-0">
            <v-slide-x-reverse-transition leave-absolute>
                <HorizontalMenuScroll id="h-menu" :menu></HorizontalMenuScroll>
            </v-slide-x-reverse-transition>
            <v-spacer></v-spacer>
            <div
                class="inline-flex align-center pl-3 pr-5 z-10"
                :class="{ 'shadow-black shadow-2xl': isOverflow }"
                style="height: 200px"
            >
                <!-- hamburger menu -->
                <!-- <v-icon @click="openDrawer" class="text-white" start>
                    <span
                        v-if="drawer"
                        class="icon-[line-md--menu-to-close-alt-transition]"
                    ></span>
                    <span
                        v-if="!drawer"
                        class="icon-[line-md--close-to-menu-alt-transition]"
                    ></span>
                </v-icon> -->
                <template v-if="$vuetify.display.smAndUp">
                    <ThemeSwitcher></ThemeSwitcher>
                </template>
                <AppBarUserMenu></AppBarUserMenu>
            </div>
        </div>
    </v-app-bar>
</template>
<script setup>
import HorizontalMenuScroll from "./HorizontalMenuScroll.vue";
import { asset } from "@/mixins";
// import menu from "@/menu.js";
import ThemeSwitcher from "./ThemeSwitcher.vue";
import AppBarUserMenu from "./AppBarUserMenu.vue";

import { ref, computed, onMounted } from "vue";

const props = defineProps(["drawer", "menu", "countMenu"]);
const emit = defineEmits(["emitDrawer"]);
const openDrawer = () => {
    emit("emitDrawer");
};

const isOverflow = ref();

function onResize() {
    const element = document.getElementById("h-menu");
    if (element) {
        isOverflow.value = element.scrollWidth > element.clientWidth;
    } else {
        return false;
    }
}

onMounted(() => {
    onResize();
    window.addEventListener("resize", onResize);
});
</script>

<style scoped>
.boxed-navbar {
    display: flex;
    align-items: center;
    inline-size: 100%;
}
</style>
