<template>
    <div class="boxed-navbar px-5">
        <div class="flex align-center">
            <v-avatar color="white" size="50" rounded class="p-1">
                <v-img
                    :src="asset('images/logo/Logo Si Cerdik.webp')"
                    height="100%"
                />
            </v-avatar>
            <!-- v-if="$vuetify.display.width > 393" -->
            <div class="ml-3 text-white" v-if="$vuetify.display.width > 350">
                <div class="font-bold text-[13pt] sm:text-xl">SI-CERDIK</div>
                <div class="text-[9pt] sm:text-sm -mt-1">RSUD Cibinong</div>
            </div>
        </div>
        <!-- <v-toolbar-title>Vuetify</v-toolbar-title> -->
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition leave-absolute>
            <HorizontalMenu :menu="menu"></HorizontalMenu>
        </v-slide-x-reverse-transition>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition>
            <v-icon
                v-if="$vuetify.display.width <= 1160"
                @click="openDrawer"
                class="text-white"
                start
            >
                <span
                    :key="key"
                    v-if="drawer"
                    class="icon-[line-md--menu-to-close-alt-transition]"
                ></span>
                <span
                    :key="key"
                    v-if="!drawer"
                    class="icon-[line-md--close-to-menu-alt-transition]"
                ></span>
            </v-icon>
            <!-- <v-app-bar-nav-icon
                @click="openDrawer"
                class="text-white"
                v-if="$vuetify.display.width <= 1160"
            ></v-app-bar-nav-icon> -->
        </v-slide-x-reverse-transition>
        <template v-if="$vuetify.display.smAndUp">
            <ThemeSwitcher></ThemeSwitcher>
        </template>
        <AppBarUserMenu></AppBarUserMenu>
    </div>
</template>
<script setup>
// import menu from "@/menu.js";
import ThemeSwitcher from "./component/ThemeSwitcher.vue";
import HorizontalMenu from "./component/HorizontalMenu.vue";
import AppBarUserMenu from "./component/AppBarUserMenu.vue";
import { asset } from "@/mixins";
import { ref, watch } from "vue";

const props = defineProps(["drawer", "menu", "countMenu"]);
const emit = defineEmits(["emitDrawer"]);
const openDrawer = () => {
    emit("emitDrawer");
    key.value += 1;
};

const key = ref(false);
watch(
    () => props.drawer,
    () => {
        key.value += 1;
    }
);
</script>
