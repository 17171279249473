export default [
    {
        path: "/master-data/univeristies",
        name: "univeristies",
        component: () =>
            import("@/pages/masterdata/universities/Universities.vue"),
        meta: {
            pageTitle: "Universitas",
            icon: "icon-[tabler--building-bank]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/stases",
        name: "stases",
        component: () => import("@/pages/masterdata/stase/Stase.vue"),
        meta: {
            pageTitle: "Stase",
            icon: "icon-[tabler--building-hospital]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/lecturers",
        name: "lecturers",
        component: () => import("@/pages/masterdata/lecturer/Lecturer.vue"),
        meta: {
            pageTitle: "Dosen",
            icon: "icon-[mdi--lecture]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/doctor",
        name: "doctor",
        component: () => import("@/pages/masterdata/doctor/Doctor.vue"),
        meta: {
            pageTitle: "Dokter",
            icon: "icon-[tabler--stethoscope]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/standar-penilaian",
        name: "standarPenilaian",
        component: () =>
            import("@/pages/masterdata/standarpenilaian/StandarPenilaian.vue"),
        meta: {
            pageTitle: "Standar Penilaian",
            icon: "icon-[tabler--checkbox]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/jenis-penilaian",
        name: "jenisPenilaian",
        component: () =>
            import("@/pages/masterdata/jenispenilaian/JenisPenilaian.vue"),
        meta: {
            pageTitle: "Jenis Penilaian",
            icon: "icon-[tabler--list-check]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/students",
        name: "students",
        component: () => import("@/pages/masterdata/student/Student.vue"),
        meta: {
            pageTitle: "Peserta Didik",
            icon: "icon-[mdi--account-student]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
        children: [
            {
                path: "verification/:ulid",
                name: "studentVerification",
                component: () =>
                    import(
                        "@/pages/masterdata/student/StudentVerification.vue"
                    ),
                meta: {
                    layout: "LayoutFull",
                    pageTitle: "Verifikasi Peserta Didik",
                    resource: "admin",
                    action: "read",
                },
            },
            {
                path: "student-profile/:ulid",
                name: "studentProfile",
                component: () =>
                    import(
                        "@/pages/masterdata/student/StudentVerification.vue"
                    ),
                // import("@/pages/profile/StudentProfile.vue"),

                meta: {
                    layout: "LayoutFull",
                    pageTitle: "Profil Peserta Didik",
                    resource: "admin",
                    action: "read",
                },
            },
        ],
    },
    {
        path: "/master-data/admins",
        name: "admins",
        component: () => import("@/pages/masterdata/users/User.vue"),
        meta: {
            pageTitle: "Admin",
            icon: "icon-[ic--twotone-manage-accounts]",
            layout: "LayoutFull",
            resource: "all",
            action: "read",
        },
    },
];
