<template>
    <v-app class="overflow-hidden" id="layout">
        <v-defaults-provider :defaults="defaultProvider">
            <!-- start v-navigation-drawer -->
            <v-navigation-drawer
                v-model="drawer"
                temporary
                location="right"
                flat
                touchless
                v-if="$vuetify.display.mdAndUp"
            >
                <!-- :style=" drawer && $vuetify.display.smAndDown ? 'bottom: 64px;' : 'bottom: 0px' " -->
                <v-list>
                    <v-list-item title="SI-CERDIK" subtitle="RSUD Cibinong">
                        <template #prepend>
                            <v-avatar
                                color="white"
                                size="45"
                                rounded
                                class="p-1"
                            >
                                <v-img
                                    :src="
                                        asset('images/logo/Logo Si Cerdik.webp')
                                    "
                                    height="100%"
                                />
                            </v-avatar>
                        </template>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list density="compact" nav>
                    <template v-for="m in menu">
                        <v-list-item
                            v-if="m.type == 'btn' && $can('read', m.access)"
                            :title="m.title"
                            :to="{ name: m.name }"
                        >
                            <template #prepend>
                                <v-icon :class="m.icon"></v-icon>
                            </template>
                        </v-list-item>
                        <v-list-group
                            v-if="
                                m.type == 'dropdown' && $can('read', m.access)
                            "
                            :value="m.title"
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" :title="m.title">
                                    <template #prepend>
                                        <v-icon :class="m.icon"></v-icon>
                                    </template>
                                </v-list-item>
                            </template>
                            <template v-for="l in m.list">
                                <v-list-item
                                    v-if="$can('read', l.access)"
                                    :title="l.title"
                                    :to="{ name: l.name }"
                                    link
                                >
                                    <template #prepend>
                                        <v-icon :class="l.icon"></v-icon>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-group>
                    </template>
                    <div style="height: 65px"></div>
                </v-list>
            </v-navigation-drawer>
            <!-- end v-navigation-drawer -->

            <!-- app bar top -->
            <!-- banner offline -->
            <v-app-bar v-if="offline" style="z-index: 1006">
                <v-banner :sticky="true" lines="two" height="fit-content">
                    <template v-slot:prepend>
                        <v-avatar color="red">
                            <v-icon class="icon-[tabler--wifi-off]"></v-icon>
                        </v-avatar>
                    </template>
                    <template v-slot:text>
                        Oops, anda offline. periksa koneksi internet anda.
                    </template>
                </v-banner>
            </v-app-bar>
            <v-overlay v-model="offline" persistent z-index="1005"></v-overlay>

            <!-- banner install app -->
            <v-expand-transition>
                <v-app-bar
                    v-if="deferredPrompt != null && banner"
                    :order="$vuetify.display.mdAndUp ? 0 : 1"
                    :location="$vuetify.display.mdAndUp ? 'top' : 'bottom'"
                    :height="$vuetify.display.mdAndUp ? '60' : '90'"
                    class="flex align-center justify-center"
                >
                    <v-banner lines="two" border="none" class="px-3">
                        <div
                            class="flex justify-between align-center gap-3 w-full"
                        >
                            <div class="flex gap-5 align-center">
                                <div>
                                    <v-avatar color="primary">
                                        <v-icon
                                            class="icon-[tabler--download]"
                                        ></v-icon>
                                    </v-avatar>
                                </div>
                                <div>
                                    Install aplikasi SI-CERDIK di perangkat mu.
                                </div>
                            </div>
                            <div class="flex align-center gap-1">
                                <v-btn
                                    @click="installApp"
                                    color="primary"
                                    variant="elevated"
                                    rounded="lg"
                                    class="w-fit"
                                    size="small"
                                >
                                    install
                                </v-btn>
                                <v-btn
                                    @click="installLater"
                                    color="primary"
                                    variant="text"
                                    rounded="lg"
                                    class="w-fit"
                                    size="small"
                                    icon
                                >
                                    <v-icon
                                        class="icon-[ic--round-close]"
                                    ></v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-banner>
                </v-app-bar>
            </v-expand-transition>

            <v-app-bar
                aspect-ratio="1"
                flat
                absolute
                color="#7344ed"
                :height="$vuetify.display.mdAndUp ? 100 : 80"
                extended
                style="z-index: 1"
                class="appbar-background"
            >
                <navigation-bar
                    :countMenu
                    :menu
                    v-if="$vuetify.display.mdAndUp"
                    @emitDrawer="handleDrawer"
                    :drawer="drawer"
                ></navigation-bar>
                <template v-else>
                    <div class="boxed-navbar">
                        <div class="flex align-center pl-5 pt-3">
                            <v-avatar
                                color="white"
                                size="45"
                                rounded
                                class="p-1"
                            >
                                <v-img
                                    :src="
                                        asset('images/logo/Logo Si Cerdik.webp')
                                    "
                                    height="100%"
                                />
                            </v-avatar>
                            <!-- v-if="$vuetify.display.width > 393" -->
                            <div
                                class="ml-3 text-white"
                                v-if="$vuetify.display.width > 350"
                            >
                                <div class="font-bold text-[13pt] sm:text-xl">
                                    SI-CERDIK
                                </div>
                                <div class="text-[9pt] sm:text-sm -mt-1">
                                    RSUD Cibinong
                                </div>
                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="pr-5 pt-3">
                            <v-avatar
                                size="45"
                                class="ml-2 border-2 border-white border-solid"
                            >
                                <img
                                    v-viewer
                                    :src="asset(authStore.user.avatar)"
                                    @error="replaceAvaByDefault"
                                />
                            </v-avatar>
                        </div>
                    </div>
                    <!-- jika mobile view -->
                </template>
            </v-app-bar>

            <v-main id="main" class="circle-background layout-page-content">
                <router-view v-slot="{ Component }">
                    <transition name="scale-slide" mode="out-in">
                        <keep-alive
                            :exclude="['AttendanceLogs', 'HomeNew']"
                            :max="10"
                        >
                            <component :is="Component" />
                        </keep-alive>
                    </transition>
                </router-view>
                <div
                    :style="
                        $vuetify.display.mdAndUp
                            ? 'height: 160px'
                            : 'height: 80px'
                    "
                ></div>
                <div class="footer mt-10" v-if="$vuetify.display.mdAndUp">
                    <v-divider class="w-full pl-1"></v-divider>
                    <div
                        class="content flex justify-between align-center !text-gray-500 text-sm"
                    >
                        <div>
                            Copyright © 2024
                            <br />
                            IT System Development - RSUD Cibinong
                        </div>
                        <!-- <div class="text-right">
                            <a href="www.freepik.com" target="_blank">Freepik</a>
                        </div> -->
                    </div>
                </div>
            </v-main>
            <!-- bottom app bar -->
            <v-expand-transition>
                <template v-if="$vuetify.display.smAndDown">
                    <BottomAppBar
                        :menu
                        :countMenu
                        @emitDrawer="handleDrawer"
                        :drawer="drawer"
                    ></BottomAppBar>
                </template>
            </v-expand-transition>
        </v-defaults-provider>
    </v-app>
</template>

<script setup>
import { asset, replaceAvaByDefault } from "@/mixins";
import menu from "@/menu";
import NavigationBar from "./NavigationBar.vue";
import BottomAppBar from "./component/BottomAppBar.vue";
import {
    ref,
    onMounted,
    computed,
    provide,
    reactive,
    onBeforeUnmount,
} from "vue";
import { useTheme, useDisplay } from "vuetify";
import ability from "@/plugins/casl/ability";

import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();

const theme = useTheme();
const { xs } = useDisplay();
const isDark = computed(() => {
    let current = theme.current.value;
    return current.dark;
});

const countMenu = computed(() => {
    return menu.filter((f) => {
        return ability.can("read", f.access);
    }).length;
});
const isDisplay = computed(() => {
    return xs;
});

provide("$isDark", isDark);

const defaultProvider = reactive({
    VCard: {
        border: isDark,
    },
    VToolbar: {
        border: isDark,
    },
    VDialogFull: {
        fullscreen: xs,
        persistent: true,
        scrollable: true,
    },
});

const drawer = ref(false);
const offsetTop = ref(0);

const handleDrawer = () => {
    drawer.value = !drawer.value;
};

const onScroll = () => {
    offsetTop.value = window.top.scrollY;
};

const offline = ref(false);
function handleConnectionChange() {
    offline.value = !navigator.onLine;
}

const banner = ref(false);
provide("$banner", banner);
let deferredPrompt = ref(window._deferredPrompt);
provide("$deferredPrompt", deferredPrompt);

async function installApp() {
    banner.value = false;
    if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === "accepted") {
            deferredPrompt.value = null;
        } else {
            deferredPrompt.value = window._deferredPrompt;
        }
    }
}

function installLater() {
    localStorage.setItem("installLater", JSON.stringify(true));
    banner.value = false;
}

onMounted(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener("online", handleConnectionChange);
    let install = JSON.parse(localStorage.getItem("installLater"));
    if (
        !install &&
        !authStore.isIos &&
        !window.matchMedia("(display-mode: standalone)").matches
    ) {
        setTimeout(() => {
            banner.value = true;
        }, 10000);
    }
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
    window.removeEventListener("offline", handleConnectionChange);
    window.removeEventListener("online", handleConnectionChange);
});

// app.config.globalProperties.$offsetTop = offsetTop.value;
</script>

<style scoped>
/* .layout-page-content {
    inline-size: 100%;
    margin-inline: auto;
    max-inline-size: 1400px;
    width: 100vw;
} */

.layout-page-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.footer .content {
    height: 70px;
    padding-left: 7%;
    padding-right: 7%;
}

.accelerate {
    transform: translateZ(0) !important;
    transform: translate3d(0, 0, 0) !important;
    will-change: transform, opacity;
}
</style>
